
import {defineComponent} from "vue";
import LoadingDots from "@/components/LoadingDots.vue";

import Throttler from "@/services/search/throttler";
import * as SearchService from "@/services/search";
import {SearchGroup, SearchItem} from "@/services/search/types";

export default defineComponent({
  name: "SearchPage",
  components: {LoadingDots},
  data: () => ({
    isFetching: false,
    result: [] as SearchGroup[],
    filteredGroups: [] as string[],
  }),
  computed: {
    searchQuery() {
      return this.$route.query.query as string;
    },
    allItems(): SearchGroup[] {
      return this.result
          .filter(group => !this.filteredGroups.length || this.filteredGroups.includes(group.code));
    }
  },
  methods: {
    async search(query: string) {
      if(query != this.searchQuery) {
        window.location.href = this.$router.resolve({name: 'search',query: {
          query
          }}).fullPath
      }
      this.isFetching = true;
      this.result = await SearchService.detail(query);
      this.isFetching = false;
    },
    resolveRoute(group: SearchGroup, item: SearchItem) {
      try {
        return this.$router.resolve({name: group.code, params: {code: item.code}})
      } catch (e) {
        return "";
      }
    },
  },
  mounted() {
    if(this.searchQuery){
      this.search(this.searchQuery);
    }
  }
})
