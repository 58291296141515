import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ed43bd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-3" }
const _hoisted_2 = { class: "human-block" }
const _hoisted_3 = { class: "human-picture-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "human-bio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allHumans, (human) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-6 col-sm-6 col-md-4 col-lg-3",
        key: human.id
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.humanPage(human)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "human-picture",
                  src: human.picture,
                  alt: ""
                }, null, 8, _hoisted_4)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", null, _toDisplayString(human.name), 1),
                _createElementVNode("h4", null, _toDisplayString(human.life_dates), 1)
              ])
            ])
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}