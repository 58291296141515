
import {defineComponent, PropType} from 'vue'
import {AudioInterface} from "@/modules/audio/AudioInterface";

export default defineComponent({
  name: "WaveButton",
  props: {
    audio: {
      type: Object as PropType<AudioInterface>,
      required: true,
    }
  },
  computed: {
    playing() {
      return this.audio?.isPlaying
    }
  }
})
