import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center pt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_skew_nav = _resolveComponent("skew-nav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_popup_view = _resolveComponent("popup-view")!
  const _component_scroll_top = _resolveComponent("scroll-top")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_skew_nav, { ref: "nav" }, null, 512)
    ]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createElementVNode("div", null, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
            default: _withCtx(() => [
              _createVNode(_Transition, { name: "fade" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_popup_view),
    (_ctx.$route.name !== 'operations' && _ctx.$route.name !== 'histories' && _ctx.$route.name !== 'weapons' && _ctx.$route.name !== 'Home')
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "© 2023 Все права защищены"))
      : _createCommentVNode("", true),
    _createVNode(_component_scroll_top)
  ]))
}