
import PlaySvg from "@/modules/audio/svg/PlaySvg.vue";
import PauseSvg from "@/modules/audio/svg/PauseSvg.vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "PlayButton",
  components: {PauseSvg, PlaySvg},
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleClick() {
      if (this.modelValue) {
        this.$emit("update:modelValue", false);
      } else {
        this.$emit("update:modelValue", true);
      }
    }
  }
})
