import router from "@/router/index";

export interface RouteAttributes {
    showDesktop?: boolean
}

export class AlphaRoute {

    constructor(
        public name: string,
        public url: string,
        public icon?: string,
        public attributes: RouteAttributes = {}
    ) {
    }
}

export const alphaRoutes: Readonly<AlphaRoute>[] = [
    new AlphaRoute("История", "/history/", "/images/ion_newspaper.svg"),
    new AlphaRoute("Операции", "/operation/", "/images/operation.svg"),
    new AlphaRoute("Вооружение", "/weapon/", "/images/weapon.svg"),
    new AlphaRoute("Люди А", "/human/", "/images/human.svg"),
    new AlphaRoute("3D Экскурсии","/excursion/","/images/excursion.svg",{
        showDesktop: false
    }),
];
