
import {defineComponent} from "vue";
import Point2D from "@/modules/helpers/Point2D";

export default defineComponent({
  name: "CirclePopup",
  data: ()=>({
    imageLoaded: false,
    contentCenter: new Point2D(0,0),
    circleCenter: new Point2D(0,0),
  }),
  methods: {
    updateLine(){

      if(this.$refs.circle){
        const circleRect = (this.$refs.circle as HTMLImageElement).getBoundingClientRect();
        const targetRect = (this.$refs.pointTarget as HTMLElement).getBoundingClientRect();

        this.circleCenter = new Point2D(
            circleRect.width/2,
            circleRect.height/2
        );

        this.contentCenter = new Point2D(
            targetRect.x - circleRect.x,
            targetRect.y - circleRect.y
        );
      }
    }
  }
});
