
import {defineComponent} from "vue";
import ImageBackground from "../components/ImageBackground.vue";
import {Weapon} from "@/services/weapon/types";
import {useWeaponsStore} from "@/services/weapon/store";
import Collection from "@/modules/collections/Collection";
import GroupedSlider from "@/components/GroupedSlider.vue";

export default defineComponent({
  name: "WeaponsPage",
  components: {
    GroupedSlider,
    ImageBackground,
  },
  data: () => ({
    store: useWeaponsStore(),
    weaponTypeTranslation: {
      "rifle": "Штурмовая винтовка",
      "submachine_gun": "Пистолет-пулемет",
      "sniper": "Снайперская винтовка",
      "pistol": "Пистолеты",
      "grenade" : "Граната",
      "machine_gun" : "Пулемет",
      "grenade_gun" : "Гранатомет"
    } as { [key in symbol | string]: string }
  }),
  computed: {
    weapons(): Weapon[] {
      return this.store.allWeapons.map(
          weapon => {
            weapon.type = this.translateType(weapon.type);
            return weapon;
          }
      );
    },
    allTypes(): string[] {
      return new Collection(this.weapons).pluck("type").unique().values();
    },
    allTranslatedTypes() {
      const translations = this.weaponTypeTranslation;
      type TTranslation = typeof translations;
      return this.allTypes.map(
          type => translations[type as keyof TTranslation] || type
      );
    },
  },
  methods: {
    weaponPage(weapon: Weapon) {
      return this.$router.resolve({
        name: 'weapon',
        params: {code: weapon.code || "ak47"}
      }).href;
    },
    translateType(type: string) {
      return this.weaponTypeTranslation[type];
    }
  },
  mounted() {
    this.store.fetchAll();
  }
});
