
import {defineComponent} from "vue";
import {PropType} from "vue";
import {Human} from "@/services/human/types";
import {Swiper, SwiperSlide} from "swiper/vue";
import SwiperClass from "swiper/types/swiper-class";
import {Month} from "@/modules/helpers/Month";
import {FreeMode, Mousewheel} from "swiper";

export default defineComponent({
  name: "HumansSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    humans: {
      type: Object as PropType<Human[]>,
      required: true,
    }
  },
  data: () => ({
    currentOperationIndex: 0,
    isOperationSliderDragging: false,
    currentYearIndex: 0,
    modules: [FreeMode, Mousewheel],
    swiperBreakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      1000: {
        slidesPerView: 3,
      },
    }
  }),
  methods: {
    onSlideChange(swiper: SwiperClass) {
      this.currentOperationIndex = swiper.realIndex;
    },
    convertToDate(timestamp: number) {
      const date = new Date(timestamp);

      return new Month(date.getMonth()).withDay(date.getDate()) + ` ${date.getFullYear()}`;
    },
    onProgress(swiper: typeof Swiper) {
      swiper.updateActiveIndex();
      swiper.updateSlidesClasses();
    },
    humanPage(human: Human) {
      return this.$router.resolve({
        name: 'human',
        params: {code: human.code || "ak47"}
      }).href;
    }
  },
  computed: {
    allHumans(): Human[] {
      return this.humans;
    }
  }
})
