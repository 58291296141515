import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02525e73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed-background" }
const _hoisted_2 = {
  key: 0,
  class: "fixed-background-overlay"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.overlay)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      src: _ctx.src,
      alt: ""
    }, null, 8, _hoisted_3)
  ]))
}