
import {defineComponent} from 'vue'
import {Swiper, SwiperSlide} from "swiper/vue";
import {Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import SwiperClass from "swiper/types/swiper-class";
import FancyBox from "@/components/gallery/FancyBox.vue";


export default defineComponent({
  name: "BigGallery",
  components: {FancyBox, SwiperSlide, Swiper},
  props: {
    images: {
      type: Array,
      required: true,
    }
  },
  data: ()=>({
    modules: [Pagination, Autoplay, Navigation,Thumbs],
    breakpoints: {
      0: {
        slidesPerView: 2
      },
      1024: {
        slidesPerView: 4,
      }
    },
    slideIndex: 0,
    thumbsSwiper: null as SwiperClass | null,
  }),
  methods: {
    setThumbsSwiper(swiper: SwiperClass) {
      this.thumbsSwiper = swiper
    }
  }
})
