
import {defineComponent} from "vue";

export interface AskItem {
  question: string;
  picture: string;
}

export default defineComponent({
  name: "PopupInput",
  props: {
    overlay: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "center",
    }
  },
  data: () => ({
    asks: [] as AskItem[],
    resolve: null as ((index: number) => void) | null,
  }),
  methods: {
    ask(asks: AskItem[]) {
      if (this.resolve) {
        this.resolve(-1);
      }
      this.asks = asks;
      return new Promise(resolve => {
        this.resolve = resolve;
      });
    },
    input(value: number) {
      if (this.resolve) {
        this.resolve(value);
        this.resolve = null;
      }
      this.asks = [];
    }
  }
});
