import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06406f8d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "page-wrapper",
  style: {"opacity":"1 !important"}
}
const _hoisted_2 = {
  class: "container",
  style: {"opacity":"1 !important"}
}
const _hoisted_3 = {
  key: 0,
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_flat_nav = _resolveComponent("flat-nav")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_page_header),
      (_ctx.$route.name !== 'human')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_flat_nav, { routes: _ctx.routes }, null, 8, ["routes"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}