
import {defineComponent} from "vue";
import {PropType} from "vue";
import RippleBlock from "@/components/RippleBlock.vue";

interface FlatOption {
  name: string;
  value: string;
  icon?: string;
}

export default defineComponent({
  name: "FlatSelect",
  props: {
    modelValue: {
      type: Object as PropType<string[]>,
      required: true,
    },
    options: {
      type: Object as PropType<FlatOption[]>,
      required: true
    },
    toggleable: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    select(option: FlatOption) {
      if (this.toggleable) {
        if (this.optionActive(option)) {
          const index = this.modelValue.indexOf(option.value);
          const options = this.modelValue.slice();
          options.splice(index, 1);
          this.$emit("update:modelValue", options);
        } else {
          const options = this.modelValue.slice();
          options.push(option.value);
          this.$emit("update:modelValue", options);
        }
      } else {
        this.$emit("update:modelValue", [option.value]);
      }
    },
    optionActive(option: FlatOption): boolean {
      return this.modelValue.includes(option.value);
    }
  }
});
