
import {defineComponent} from 'vue';

import {Swiper, SwiperSlide} from 'swiper/vue';
import SwiperClass from 'swiper/types/swiper-class';

import {EffectCoverflow, Mousewheel, Navigation} from 'swiper';

export default defineComponent({
  name: 'CircularSelect',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    values: {
      required: true,
    },
    isOperations: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    windowWidth: window.innerWidth,
    navigation: {
      //Next
      nextEl: '.btn-circle-right',//The name of the next label can be customized
      //Previous
      prevEl: '.btn-circle-left'//The name of the previous label can be customized
    },
  }),
  methods: {
    onSlideChange(swiper: SwiperClass) {
      this.$emit('update:modelValue', swiper.activeIndex);
    },
    setWidth() {
      this.windowWidth = window.innerWidth
    }
  },
  computed: {
    slider(): SwiperClass {
      return (this.$refs.slider as typeof Swiper).$el.swiper;
    },
    modules() {
      return [EffectCoverflow, Mousewheel, Navigation]
    }
  },
  watch: {
    modelValue() {
      this.slider.slideTo(this.modelValue);
    },
  },
  mounted() {
    window.addEventListener('resize', this.setWidth)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setWidth)
  }
});
