
import {defineComponent} from "vue";
import {VttFile} from "@/modules/audio/VttFile";

export default defineComponent({
  name: "VttAudio",
  props: {
    time: {
      required: true,
      type: Number
    },
    vtt: {
      required: false,
      type: String,
    },
    text: {
      required: true,
      type: String,
    }
  },
  data: () => ({
    file: new VttFile(""),
    parsedText: [] as string[],
    currentTime: 0,
  }),
  computed: {
    html() {
      if (this.currentPeriod) {
        return this.text.replace(this.currentPeriod.text, `<span class='vtt-mark'>${this.currentPeriod.text}</span>`);
      }
      return this.text;
    },
    currentPeriod() {
      return this.file.find(Math.round(this.time))
    },
    currentText() {
      if (this.currentPeriod) {
        return this.currentPeriod.text
      }
      return "";
    }
  },
  async mounted() {

    if (this.vtt) {
      const text = await fetch(this.vtt);
      this.file = new VttFile(await text.text());
    }
    if (this.vtt) {
      this.parsedText = [];

      const periods = this.file.allPeriods();
      let text = this.text.slice();

      for (let i = 0; i < periods.length; i++) {
        const period = periods[i];
        const index = text.indexOf(period.text);
        if (index !== -1) {
          this.parsedText.push(text.slice(0, index));
          this.parsedText.push(text.slice(index, index + period.text.length));
          text = text.slice(index + period.text.length);
        }
        if (i + 1 === periods.length) {
          this.parsedText.push(text);
        }
      }
    } else {
      this.parsedText = [this.text];
    }
  },
  watch: {
    currentText(){
      this.$nextTick(()=>{
        const activeMark = this.$el.querySelector(".vtt-mark-active") as HTMLElement;
        if(activeMark){
          const rect = activeMark.getBoundingClientRect();
          window.scrollTo(0,window.scrollY + Math.abs(rect.y) - 200);
        }
        console.log(activeMark);
      })
    }
  }
})
