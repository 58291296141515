import {SearchGroup} from "@/services/search/types";
import {apiGet} from "@/services/api";

export async function detail(query: string): Promise<SearchGroup[]> {
    return apiGet("search", {
        params: {
            query
        }
    });
}
