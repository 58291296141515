
import {defineComponent} from "vue";
import WaveSurfer from "wavesurfer.js";

export default defineComponent({
  name: "WaveSurfer",
  data: ()=>({
    wavesurfer: null as null | WaveSurfer,
  }),
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    audio: {
      required: true,
      type: String
    },
    isPlaying: {
      type: Boolean,
      required: true,
    },
    barHeight: {
      type: Number,
      default: 50,
    }
  },
  mounted() {
    this.wavesurfer = WaveSurfer.create({
      container: this.$refs.waveform as HTMLElement,
      barWidth: 2,
      height: this.barHeight,
      progressColor: "#F89E71",
      barGap: 0,
      barRadius: 2
    });
    this.wavesurfer.load(this.audio);
    setInterval(() => {
      if (this.wavesurfer) {
        this.$emit("update:modelValue", this.wavesurfer.getCurrentTime());
      }
    }, 1000);
  },
  beforeUnmount() {
      this.wavesurfer?.destroy()
  },
  watch: {
    isPlaying() {
      if (this.isPlaying) {
        this.wavesurfer?.play();
      } else {
        this.wavesurfer?.pause();
      }
    }
  }
})
