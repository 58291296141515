import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a18549f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "parallax-header p-3 fade-in-down opacity-block"
}
const _hoisted_2 = {
  class: "parallax-banner",
  ref: "banner"
}
const _hoisted_3 = { class: "parallax-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showHeader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "banner", {}, undefined, true)
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ], 64))
}