import { createApp } from 'vue';
import VueVideoPlayer from "vue-video-player";
import App from '@/App.vue';
import router from "@/router";
import {useIntersectionPlugin} from "@/modules/parallax/Intersection";
import {createPinia} from "pinia";
import {useRelaxPlugin} from "@/modules/parallax/Relax";
import {useRipplePlugin} from "@/modules/parallax/Ripple";
import {BackgroundMusic} from "@/modules/audio/BackgroundMusic";

const app = createApp(App);
app.use(router);
app.use(VueVideoPlayer);
app.use(useIntersectionPlugin);
app.use(useRelaxPlugin);
app.use(useRipplePlugin);
app.use(createPinia());
app.mount("#app");

BackgroundMusic.select(new Audio('/audio/background.mp3'));

function playBackgroundMusic() {
    if(!localStorage.getItem('background-audio-paused')){
        BackgroundMusic.play()
    }

    document.removeEventListener('click',playBackgroundMusic)
}

document.addEventListener('click',playBackgroundMusic)
