import {defineStore} from "pinia";
import {Weapon} from "@/services/weapon/types";
import * as WeaponService from "./index"

export interface WeaponStoreState {
    weapons: Weapon[]
}

export const useWeaponsStore = defineStore('weapons',{
    state: ()=>({
        weapons: [],
    } as WeaponStoreState),
    getters: {
        getByCode(state){
            return (code: string) => state.weapons.find(weapon=>weapon.code === code);
        },
        allWeapons(state): Weapon[]{
            return state.weapons;
        },
        getNextByCode() {
            return (code: string) => {
                const index = this.weapons.findIndex(operation => operation.code === code);
                if ((index + 1)  < this.weapons.length) {
                    return this.weapons[index + 1]
                } else {
                    return null;
                }
            };
        },
        getPrevByCode() {
            return (code: string) => {
                const index = this.weapons.findIndex(operation => operation.code === code);
                if ((index - 1) > 0) {
                    return this.weapons[index - 1]
                } else {
                    return null;
                }
            };
        },
    },
    actions: {
        async fetchWeapon(code: string){
            const index = this.weapons.findIndex(weapon => weapon.code === code)
            const exist = index !== -1;
            if(!exist){
                const weapon = await WeaponService.detail(code);
                weapon && this.weapons.push(weapon);
                return weapon
            }
            return this.weapons[index]
        },
        async fetchAll(){
            this.weapons = (await WeaponService.index()).sort((a,b)=> a.type.localeCompare(b.type));
        }
    }
})