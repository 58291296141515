import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f88125"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container mb-2" }
const _hoisted_2 = { class: "d-flex justify-content-end" }
const _hoisted_3 = {
  key: 0,
  class: "small-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_select = _resolveComponent("view-select")!
  const _component_flat_select = _resolveComponent("flat-select")!
  const _component_humans_grid = _resolveComponent("humans-grid")!
  const _component_humans_slider = _resolveComponent("humans-slider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_view_select, {
          class: "py-2",
          modelValue: _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValue) = $event)),
          "is-human": true,
          "open-filter": _ctx.openFilter,
          onOpenFilter: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openFilter = $event))
        }, null, 8, ["modelValue", "open-filter"])
      ]),
      (_ctx.openFilter)
        ? (_openBlock(), _createBlock(_component_flat_select, {
            key: 0,
            class: "mb-3",
            modelValue: _ctx.statusFilter,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.statusFilter) = $event)),
            options: [{name: 'Погибли в бою',value: 'dead'},{name: 'Ушедшие',value: 'departed'},{name: 'Легенды',value: 'hero'},{name: 'Командиры',value: 'commander'}]
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.modelValue === 'grid')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_humans_grid, { humans: _ctx.allHumans }, null, 8, ["humans"])
        ]))
      : (_openBlock(), _createBlock(_component_humans_slider, {
          key: 1,
          humans: _ctx.allHumans
        }, null, 8, ["humans"]))
  ]))
}