import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { style: {"position":"relative","height":"100%","display":"flex","flex-direction":"column","justify-content":"space-between"} }
const _hoisted_3 = { class: "operations-slider" }
const _hoisted_4 = { class: "operations__nav" }
const _hoisted_5 = {
  class: "skew-button-left btn-left",
  ref: "btn-left"
}
const _hoisted_6 = {
  class: "skew-button-right btn-right",
  ref: "btn-right"
}
const _hoisted_7 = { class: "angled-img" }
const _hoisted_8 = { class: "img" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "period-footer" }
const _hoisted_11 = {
  style: {"color":"#DB936C"},
  class: "text-uppercase"
}
const _hoisted_12 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_circle_popup = _resolveComponent("circle-popup")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_circular_select = _resolveComponent("circular-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_swiper, {
          "centered-slides": true,
          breakpoints: _ctx.swiperBreakpoints,
          autoHeight: true,
          mousewheel: true,
          modules: _ctx.modules,
          onProgress: _ctx.onProgress,
          onSlideChange: _ctx.onSlideChange,
          onTouchStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOperationSliderDragging = true)),
          onTouchEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOperationSliderDragging = false)),
          ref: "operation_slider",
          navigation: _ctx.navigation
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("button", _hoisted_5, " <- ", 512)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", _hoisted_6, " -> ", 512)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allOperations, (period) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                class: "period-item",
                key: period.id
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_router_link, {
                    to: _ctx.$router.resolve({name:'history',params:{code:period.code}})
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("img", {
                            src: period.picture,
                            alt: ""
                          }, null, 8, _hoisted_9)
                        ])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createElementVNode("div", _hoisted_10, [
                    isActive
                      ? (_openBlock(), _createBlock(_component_circle_popup, { key: 0 }, {
                          title: _withCtx(() => [
                            (_ctx.currentOperation)
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: _ctx.$router.resolve({name:'history',params:{code:period.code}}),
                                  class: "text-start"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("h4", null, _toDisplayString(period.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]))
                              : _createCommentVNode("", true)
                          ]),
                          subtitle: _withCtx(() => [
                            _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.convertToDate(period.start_date)), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["breakpoints", "modules", "onProgress", "onSlideChange", "navigation"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_circular_select, {
          values: _ctx.allYears,
          modelValue: _ctx.currentYearIndex,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentYearIndex) = $event)),
          "is-operations": true
        }, null, 8, ["values", "modelValue"])
      ])
    ])
  ]))
}