import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b83cfe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "d-flex flex-wrap",
  style: {"gap":"1rem"}
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "ask-item blur-block p-3 h-100 vstack justify-content-between" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-center h3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.asks.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({'fixed-background': _ctx.overlay})
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['asks-list',`position-${_ctx.position}`])
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.asks, (ask, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.input(index))
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: ask.picture,
                    alt: "",
                    class: "w-100 rounded-3"
                  }, null, 8, _hoisted_4),
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(ask.question), 1)
                ])
              ], 8, _hoisted_2))
            }), 128))
          ])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}