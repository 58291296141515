import {Operation} from "@/services/operation/types";
import {api, apiGet} from "@/services/api";

export async function detail(code: string): Promise<Operation | undefined> {
    return await apiGet(`operations/${code}`);
}

export async function index(): Promise<Operation[]> {
    return await apiGet("operations")
}
