
import {defineComponent} from "vue";
import OperationsSlider from "@/components/operations/OperationsSlider.vue";
import {useOperationsStore} from "@/services/operation/store";
import VideoBackground from "@/components/VideoBackground.vue";


export default defineComponent({
  name: "OperationsPage",
  components: {VideoBackground, /*OperationsGrid,*/ OperationsSlider, /*OperationsStaticGrid*/},
  data: ()=>({
    store: useOperationsStore(),
    view: "slider" as "slider" | "grid",
  }),
  computed: {
    allOperations() {
      return this.store.allOperations;
    }
  },
  mounted() {
    this.store.fetchAll();
  },
});
