import {defineStore} from "pinia";
import {Excursion} from "@/services/excursion/types";
import {index} from "@/services/excursion/index";

export const useExcursionStore = defineStore('excursion',{
    state: ()=>({
        excursions: [] as Excursion[]
    }),
    getters: {
        allExcursions(state) {
            return state.excursions
        }
    },
    actions: {
        async fetchAll() {
            this.excursions = await index()
        }
    }
})