
import {defineComponent} from "vue";

export default defineComponent({
  name: "SkewStack",
  props: {
    angle: {
      type: Number,
      default: 20,
    }
  },
  methods: {
    recalculateInner(){
      const wrapper = this.$refs.wrapper as HTMLElement;
      const wrapperRect = wrapper.getBoundingClientRect();
      const children = wrapper.children;

      for (const child of children) {
        if(child instanceof HTMLElement){
          const childRect = child.getBoundingClientRect();
          const height = childRect.y - wrapperRect.y + childRect.height;
          child.style["marginLeft"] = `${height * this.kSin}px`;
        }
      }
    }
  },
  computed: {
    kSin(){
      const aAngle = this.angle;
      const bAngle = 90 - aAngle;
      const aRadians = aAngle * (Math.PI / 180);
      const bRadians = bAngle * (Math.PI / 180);
      return Math.sin(aRadians) / Math.sin(bRadians);
    },
  },
  mounted() {
    window.addEventListener("resize",this.recalculateInner);
    this.recalculateInner();
  },
  updated() {
    this.recalculateInner()
  },
  beforeUnmount() {
    window.addEventListener("resize",this.recalculateInner);
  }
})
