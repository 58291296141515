import {EventEmitter} from "@/modules/events/EventEmitter";
import * as _ from "lodash";
import * as Three from "three";

export class MouseCanvasObserver extends EventEmitter {
    private canvas: HTMLCanvasElement | null;

    constructor(
        canvas: HTMLCanvasElement | null,
        private scene: Three.Scene,
        private camera: Three.Camera
    ) {
        super();
        this.canvas = canvas;
        canvas && this.setCanvas(canvas);
    }

    setCanvas(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.canvas.addEventListener("click", this.onMouseClick.bind(this));
    }

    private onMouseClick(e: MouseEvent) {
        if (!this.canvas) {
            return;
        }
        const rect = this.canvas.getBoundingClientRect();
        const point = new Three.Vector2(
            ((e.x - rect.x) / rect.width) * 2 - 1,
            -((e.y - rect.y) / rect.height) * 2 + 1
        );

        const raycaster = new Three.Raycaster();

        raycaster.setFromCamera(point, this.camera);

        let intersectedObjects = raycaster.intersectObjects(this.scene.children, true).map(intersection => intersection.object);
        intersectedObjects = intersectedObjects.filter(
            object => object.visible
        );

        intersectedObjects = _.uniqBy(intersectedObjects, function (object) {
            object.id;
        });

        intersectedObjects.forEach((object) => {
            this.emit("click", object);
        });
    }

}