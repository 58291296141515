import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-577da212"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-3" }
const _hoisted_2 = { class: "col-10 offset-1" }
const _hoisted_3 = { class: "angled-img" }
const _hoisted_4 = { class: "img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "human-bio" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_swiper, {
        "centered-slides": true,
        breakpoints: _ctx.swiperBreakpoints,
        autoHeight: true,
        mousewheel: true,
        modules: _ctx.modules,
        onProgress: _ctx.onProgress,
        onSlideChange: _ctx.onSlideChange,
        onTouchStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOperationSliderDragging = true)),
        onTouchEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOperationSliderDragging = false)),
        ref: "operation_slider"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allHumans, (human) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              class: "period-item",
              key: human.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _ctx.humanPage(human)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("img", {
                          src: human.picture,
                          alt: ""
                        }, null, 8, _hoisted_5)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("h3", null, _toDisplayString(human.name), 1),
                      _createElementVNode("h4", null, _toDisplayString(human.life_dates), 1)
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["breakpoints", "modules", "onProgress", "onSlideChange"])
    ])
  ]))
}