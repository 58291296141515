import { withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac9f83f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "small-container" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row mt-3 g-3" }
const _hoisted_5 = { class: "search-item" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_dots = _resolveComponent("loading-dots")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.search($event.target.value)), ["enter"])),
      placeholder: "Поиск...",
      type: "text",
      value: _ctx.searchQuery,
      class: "w-100"
    }, null, 40, _hoisted_2),
    (_ctx.isFetching)
      ? (_openBlock(), _createBlock(_component_loading_dots, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allItems, (group, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "col-sm-12 col-md-4 col-lg-3",
                    key: i
                  }, [
                    _createVNode(_component_router_link, {
                      to: _ctx.resolveRoute(group,item)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("img", {
                            src: item.picture,
                            class: "w-100",
                            alt: ""
                          }, null, 8, _hoisted_6),
                          _createTextVNode(" " + _toDisplayString(item.name), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                }), 128))
              ], 64))
            }), 128))
          ])
        ]))
  ]))
}