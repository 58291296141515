import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d0ec0b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onMouseenter", "onMouseleave"]
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-9" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "col-12 d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_intersection = _resolveDirective("intersection")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allPopups, (popup) => {
    return _withDirectives((_openBlock(), _createElementBlock("div", {
      key: popup.key,
      class: "popup-item blur-block p-2",
      style: _normalizeStyle({left: popup.position.x + 'px',top: popup.position.y + 'px'}),
      onMouseenter: ($event: any) => (popup.hovered = true),
      onMouseleave: ($event: any) => (popup.hovered = false)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h6", null, _toDisplayString(popup.title), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: popup.picture,
            alt: "",
            class: "w-100"
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(popup.description), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, {
            to: popup.href
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Подробнее ")
            ]),
            _: 2
          }, 1032, ["to"])
        ])
      ])
    ], 44, _hoisted_1)), [
      [_directive_intersection]
    ])
  }), 128))
}