
import {defineComponent} from "vue";
import FlatSelect from "@/components/input/FlatSelect.vue";
import {useHumansStore} from "@/services/human/store";
import {Human} from "@/services/human/types";
import HumansGrid from "@/components/humans/HumansGrid.vue";
import ViewSelect from "@/components/input/ViewSelect.vue";
import HumansSlider from "@/components/humans/HumansSlider.vue";

export default defineComponent({
  name: "HumansPage",
  components: {HumansSlider, ViewSelect, HumansGrid, FlatSelect},
  data: () => ({
    statusFilter: ["dead"] as string[],
    store: useHumansStore(),
    modelValue: 'grid' as 'grid' | 'slider',
    openFilter: true
  }),
  mounted() {
    this.store.fetchAll();
  },
  computed: {
    allHumans() {
      let humans = this.store.allHumans as Human[];
      humans = humans.filter(human => human.status.split(',').filter(status => {
        return this.statusFilter.includes(status)
      }).length > 0).sort((human1,human2) => {
        return human1.sort - human2.sort
      });

      return humans;
    }
  }
});
