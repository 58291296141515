import {PageMeta} from "@/router/meta/PageMeta";
import {RouteLocation} from "vue-router";
import {useHumansStore} from "@/services/human/store";

export async function getHumanMeta(route: RouteLocation): Promise<PageMeta> {
    const human = await useHumansStore().fetch(route.params.code as string);

    return {
        title: human.name,
        description: human.detail_text,
        picture: human.picture
    }
}