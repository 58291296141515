
import {defineComponent} from "vue";
import {PropType} from "vue";
import {Human} from "@/services/human/types";

export default defineComponent({
  name: "HumansGrid",
  props: {
    humans: {
      type: Object as PropType<Human[]>,
      required: true,
    }
  },
  methods: {
    humanPage(human: Human){
      return {
        name: 'human',
        params: {code: human.code || "ak47"}
      };
    }
  },
  computed: {
    allHumans(): Human[] {
      return this.humans
    }
  }
})
