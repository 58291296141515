import * as THREE from "three"
import {GLTF, GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader'
import {createMaterial} from "@/modules/3d/Material";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {Texture} from "three";

export async function createEnvMap(renderer: THREE.WebGLRenderer): Promise<Texture> {
    return new Promise(resolve => {
        const testLoader = new RGBELoader();
        testLoader.load("/3d/pines_small.hdr", function (texture) {
            const pmremGenerator = new THREE.PMREMGenerator(renderer);
            const CubeRenderTarget = pmremGenerator.fromEquirectangular(texture);
            const envMap = CubeRenderTarget.texture;
            texture.dispose();
            resolve(envMap);
        });
    })
}

function fetchModel(pathToModel: string): Promise<GLTF> {
    return new Promise((resolve, reject) => {

        const manager2 = new THREE.LoadingManager();

        const loader = new GLTFLoader(manager2);
        const draco = new DRACOLoader();
        draco.setDecoderPath("/3d/draco/");
        draco.setDecoderConfig({type: 'js'});
        loader.setDRACOLoader(draco);

        loader.load(
            pathToModel,
            function (model) {
                resolve(model);
            },
            undefined,
            function (e) {
                reject(e);
            }
        )
    })
}

export async function loadModel(pathToModel: string): Promise<GLTF> {
    const model = await fetchModel(pathToModel);

    model.scene.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            if (child.name == "Приклад_2") {
                child.visible = false
            }
            child.material = createMaterial(child.material);
        }
    });

    return model;
}
