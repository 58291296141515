import {defineStore} from "pinia";
import {Human} from "@/services/human/types";
import * as HumanService from "./index"

export const useHumansStore = defineStore('humans', {
    state: () => ({
        humans: [] as Human[],
        viewType: 'grid' as 'grid' | 'slider'
    }),
    getters: {
        allHumans(state) {
            return state.humans;
        },
        findByCode(state) {
            return (code: string) => state.humans.find(human => human.code === code);
        },
        getNextByCode(state) {
            return (code: string) => {
                const index = this.humans.findIndex(operation => operation.code === code);
                console.log(index, this.humans.length)
                if ((index + 1)  < this.humans.length) {
                    return this.humans[index + 1]
                } else {
                    return null;
                }
            };
        },
        getPrevByCode(state) {
            return (code: string) => {
                const index = this.humans.findIndex(operation => operation.code === code);
                console.log(index)
                if ((index - 1) > 0) {
                    return this.humans[index - 1]
                } else {
                    return null;
                }
            };
        },
    },
    actions: {
        async fetch(code: string): Promise<Human> {
            const human = await HumanService.detail(code);

            const index = this.humans.findIndex(human => human.code === code);
            if (index !== -1) {
                this.humans[index] = human;
            } else {
                this.humans.push(human);
            }

            return human;
        },
        async fetchAll() {
            this.humans = await HumanService.index();
        },
        changeViewType(type: 'grid' | 'slider') {
            this.viewType = type
        }
    }
});