import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f808cb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "weapons-container" }
const _hoisted_3 = { class: "weapon-card-wrapper" }
const _hoisted_4 = { class: "weapon-card" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "weapon-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_grouped_slider = _resolveComponent("grouped-slider")!
  const _component_image_background = _resolveComponent("image-background")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_grouped_slider, {
        entities: _ctx.weapons,
        "group-key": "type"
      }, {
        entity: _withCtx(({entity: weapon}) => [
          _createVNode(_component_router_link, {
            to: _ctx.weaponPage(weapon)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    src: weapon.picture,
                    alt: "",
                    class: "weapon-picture"
                  }, null, 8, _hoisted_5)
                ])
              ]),
              _createElementVNode("h3", _hoisted_6, _toDisplayString(weapon.name), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        _: 1
      }, 8, ["entities"])
    ]),
    _createVNode(_component_image_background, { src: "/images/banner.png" })
  ]))
}