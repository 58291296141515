
import {defineComponent} from "vue";
import WeaponView from "@/modules/3d/view/WeaponView.vue";
import {useWeaponsStore} from "@/services/weapon/store";
import {Weapon} from "@/services/weapon/types";
import PageHeader from "@/components/header/PageHeader.vue";

export default defineComponent({
  name: "WeaponPage",
  components: {
    PageHeader,
    WeaponView
  },
  data: ()=>({
    store: useWeaponsStore()
  }),
  computed: {
    code() {
      console.log('code!')
      return this.$route.params.code as string;
    },
    weapon(): Weapon | undefined {
      return this.store.getByCode(this.code);
    },
    prevRoute(): Weapon | null {
      return this.store.getPrevByCode(this.code)
    },
    nextRoute(): Weapon | null {
      return this.store.getNextByCode(this.code)
    }
  },
  mounted() {
    this.store.fetchAll();
  }
});
