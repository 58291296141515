
import {defineComponent} from 'vue'
import {useExcursionStore} from "@/services/excursion/store";
import ExcursionSlider from "@/components/excursions/ExcursionSlider.vue";
import VideoBackground from "@/components/VideoBackground.vue";

export default defineComponent({
  name: "ExcursionsPage",
  components: {VideoBackground, ExcursionSlider},
  data: ()=>({
    store: useExcursionStore()
  }),
  mounted() {
    this.store.fetchAll()
  }
})
