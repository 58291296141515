import {App, Directive} from "vue";

interface RelaxItem {
    element: HTMLElement;
    multiplier: number;
    scrollX: number;
}

const relaxElements: RelaxItem[] = [];

function setScrollParallax() {
    // if center modifier is true, we want to start the magic from the middle of the screen
    const WINDOW_POS = window.innerHeight;

    for (const relaxElement of relaxElements) {
        const el = relaxElement.element;
        // document scrolled amount - parallax container offset from top + window height
        // this make sure to update --scroll-amount only when the elements are in the viewport
        let scroll = document.documentElement.scrollTop - el.offsetTop + WINDOW_POS;

        // Make sure we dont have negative values
        if (scroll < 0) scroll = 0;

        // set the "--scroll-amount" for each parallax container
        el.style.setProperty("--scroll-amount", (scroll * relaxElement.multiplier).toString());
        el.style.setProperty("--scroll-x", (relaxElement.scrollX).toString());
        //el.style.setProperty("--multiplier", relaxElement.multiplier.toString());
    }

    // global "--scroll-amount" attached to the body
    //document.body.style.setProperty("--scroll-amount", document.documentElement.scrollTop );

    window.requestAnimationFrame(setScrollParallax);
}

export const useRelaxPlugin = {

    directive: {
        mounted(el: HTMLElement) {
            relaxElements.push({
                element: el,
                multiplier: Math.random(),
                scrollX: Math.floor(Math.random() * 100) - 50,
            });
        },
        unmounted(el: HTMLElement) {
            const index = relaxElements.findIndex(item => item.element === el);
            if (index !== -1) {
                relaxElements.splice(index, 1);
            }
        }
    } as Directive,

    install(app: App<Element>) {
        app.directive("relax", this.directive);
        requestAnimationFrame(setScrollParallax);
    }
}
