import {createRouter, createWebHistory} from "vue-router";
import OperationPage from '@/pages/OperationPage.vue'
import OperationsPage from "@/pages/OperationsPage.vue";
import HomePage from "@/pages/HomePage.vue";
import CleanLayout from "@/layouts/CleanLayout.vue";
import ContactsPage from "@/pages/ContactsPage.vue";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import WeaponsPage from "@/pages/WeaponsPage.vue";
import WeaponPage from "@/pages/WeaponPage.vue";
import HumansPage from "@/pages/HumansPage.vue";
import HumanPage from "@/pages/HumanPage.vue";
import SearchPage from "@/pages/SearchPage.vue";
import {getHumanMeta} from "@/router/meta/Human";
import {getOperationMeta} from "@/router/meta/Operation";
import {usePopupStore} from "@/services/popup/store";
import HistoriesPage from "@/pages/HistoriesPage.vue";
import HistoryPage from "@/pages/HistoryPage.vue";
import ExcursionsPage from "@/pages/ExcursionsPage.vue";
import {useAppStore} from "@/services/app/store";
import ExcursionPage from "@/pages/ExcursionPage.vue";
import {PageMeta} from "@/router/meta/PageMeta";
import {stripTags} from "@/helpers/stripTags";
import {getWeaponMeta} from "@/router/meta/Weapon";
import {getHistoryMeta} from "@/router/meta/History";

const routes = [
    {path: '/:catchAll(.*)', component: NotFoundPage},
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        meta: {
            layout: CleanLayout,
            title: "Главная"
        }
    },
    {
        path: '/operation/',
        name: 'operations',
        component: OperationsPage,
        meta: {
            title: "Операции"
        }
    },
    {
        path: '/weapon/',
        name: 'weapons',
        component: WeaponsPage,
        meta: {
            title: "Вооружение"
        }
    },
    {
        path: '/weapon/:code/',
        name: 'weapon',
        component: WeaponPage,
        meta: {
            getMeta: getWeaponMeta,
            title: "Вооружение",
            layout: CleanLayout,
        }
    },
    {
        path: '/history/',
        name: 'histories',
        component: HistoriesPage,
        meta: {
            title: "Истории"
        }
    },
    {
        path: '/history/:code/',
        name: 'history',
        component: HistoryPage,
        meta: {
            layout: CleanLayout,
            getMeta: getHistoryMeta,
            title: "История"
        }
    },
    {
        path: '/operation/:code/',
        name: 'operation',
        component: OperationPage,
        meta: {
            layout: CleanLayout,
            getMeta: getOperationMeta,
            title: "Операция"
        }
    },
    {
        path: "/contacts",
        name: "contacts",
        component: ContactsPage,
        meta: {
            title: "Контакты"
        }
    },
    {
        path: "/human/",
        name: "humans",
        component: HumansPage,
        meta: {
            title: "Люди А"
        }
    },
    {
        path: "/human/:code",
        name: "human",
        component: HumanPage,
        meta: {
            getMeta: getHumanMeta,
            title: "Люди А"
        }
    },
    {
        path: "/search/",
        name: "search",
        component: SearchPage
    },
    {
        path: "/excursion/",
        name: "excursions",
        component: ExcursionsPage
    },
    {
        path: "/excursion/:code",
        name: "excursion",
        component: ExcursionPage
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to)=>{
    usePopupStore().clearAll();

    if(to.meta && typeof to.meta.title === "string") {
        useAppStore().setTitle(`${to.meta.title} - Музей альфы`)
        if(typeof to.meta.getMeta === "function") {
            const meta = await to.meta.getMeta(to) as PageMeta
            useAppStore().setTitle(`${meta.title}`)
            useAppStore().setDescription(`${stripTags(meta.description)}`)
        }
    }else {
        useAppStore().setTitle("Музей альфы")
    }

})

export default router;
