import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85720cdc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "small-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_excursion_slider = _resolveComponent("excursion-slider")!
  const _component_video_background = _resolveComponent("video-background")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_excursion_slider, {
      excursions: _ctx.store.allExcursions
    }, null, 8, ["excursions"]),
    _createVNode(_component_video_background, { src: "/video/alpha.mp4" })
  ]))
}