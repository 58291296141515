import {App, Directive} from "vue"

const RIPPLE_LIFETIME = 600;

function createRipple(element: HTMLElement,x: number,y: number){
    const ripple = document.createElement("span");
    ripple.classList.add("ripple");
    ripple.style.left = `${x-12}px`;
    ripple.style.top = `${y-12}px`;
    element.appendChild(ripple);
    setTimeout(()=>{
        element.removeChild(ripple);
    },RIPPLE_LIFETIME)
}

export const useRipplePlugin = {

    onClick: function(event: MouseEvent){
        const {screenX, clientY} = event;
        let target: HTMLElement = event.target as HTMLElement;
        const rippleTarget = target.closest(".has-ripples") as HTMLElement;
        if(rippleTarget !== target){
            target = rippleTarget;
        }

        if(target instanceof HTMLElement){
            const rect = target.getBoundingClientRect();
            const offsetX = screenX - rect.x;
            const offsetY = clientY - rect.y;

            createRipple(rippleTarget,offsetX,offsetY);
        }
    },

    onHover: function(event: MouseEvent) {
        const mouseEnterEvent = event as MouseEvent & {fromElement: HTMLElement};
        if(!mouseEnterEvent.fromElement || !mouseEnterEvent.fromElement.classList.contains('ripple')){
            useRipplePlugin.onClick(event);
        }
    },

    clickDirective: {
        mounted(el: HTMLElement) {
            el.addEventListener("click",useRipplePlugin.onClick);
            el.classList.add("has-ripples");
        },
        updated(element: HTMLElement){
            element.classList.add("has-ripples");
        }
    } as Directive,

    hoverDirective: {
        mounted(el: HTMLElement) {
            el.addEventListener("mouseenter",useRipplePlugin.onHover);
            el.classList.add("has-ripples");
        },
        updated(element: HTMLElement){
            element.classList.add("has-ripples");
        }
    } as Directive,

    install(app: App<Element>) {
        app.directive("ripple", this.clickDirective);
        app.directive("hover-ripple", this.hoverDirective);
    }
}
