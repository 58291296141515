
import {defineComponent} from "vue";

export default defineComponent({
  name: "ParallaxView",
  data: ()=>({
    showHeader: false
  }),
  created() {
    document.addEventListener("scroll",this.handleScroll);
  },
  unmounted() {
    document.removeEventListener("scroll",this.handleScroll);
  },
  computed: {
    banner(){
      return this.$refs.banner as HTMLElement;
    }
  },
  methods: {
    handleScroll(){
      const rect = this.banner.getBoundingClientRect();
      this.showHeader = Math.abs(rect.y) >= rect.height;
    }
  }
})
