
import {defineComponent} from 'vue'
import {Swiper, SwiperSlide} from "swiper/vue";
import CirclePopup from "@/components/popup/CirclePopup.vue";
import {FreeMode, Mousewheel, Navigation} from "swiper";

export default defineComponent({
  name: "ExcursionSlider",
  components: {CirclePopup, SwiperSlide, Swiper},
  props: {
    excursions: {
      type: Array,
      required: true,
    }
  },
  data: ()=>({
    modules: [FreeMode, Mousewheel, Navigation],
    navigation: {
      //Next
      nextEl: '.btn-right',//The name of the next label can be customized
      //Previous
      prevEl: '.btn-left'//The name of the previous label can be customized
    },
    swiperBreakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      1000: {
        slidesPerView: 3,
      },
    }
  }),
  updated() {
    (this.$refs.slider as any).$el.swiper.slideTo(1)
  }
})
