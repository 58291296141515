
import {defineComponent} from "vue";

export default defineComponent({
  name: "VideoBackground",
  props: {
    src: {
      type: String,
      required: true,
    }
  }
});
