
import {defineComponent} from "vue";
import PageHeader from "@/components/header/PageHeader.vue";
import FlatNav from "@/components/nav/FlatNav.vue";
import {AlphaRoute, alphaRoutes} from "@/router/routes";

export default defineComponent({
  name: "HeaderLayout",
  components: {FlatNav, PageHeader},
  data: ()=>({
    routes: alphaRoutes
  }),
  created() {
    this.routes = this.routes.slice();
    this.routes.unshift(new AlphaRoute("Главная","/"))
  }
})
