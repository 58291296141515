import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-489fa640"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operations__nav" }
const _hoisted_2 = {
  class: "skew-button-left btn-left",
  ref: "btn-left"
}
const _hoisted_3 = {
  class: "skew-button-right btn-right",
  ref: "btn-right"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "angled-img" }
const _hoisted_6 = { class: "img" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "period-footer" }
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_popup = _resolveComponent("circle-popup")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_swiper, {
      "centered-slides": true,
      breakpoints: _ctx.swiperBreakpoints,
      autoHeight: true,
      mousewheel: true,
      modules: _ctx.modules,
      navigation: _ctx.navigation,
      "initial-slide": 1,
      ref: "slider"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createElementVNode("button", _hoisted_2, " <- ", 512)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", _hoisted_3, " -> ", 512)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.excursions, (period) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            class: "period-item",
            key: period.id
          }, {
            default: _withCtx(({ isActive }) => [
              _createElementVNode("a", {
                href: _ctx.$router.resolve({name:'excursion', params:{code: period.code}}).href
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("img", {
                      src: period.picture,
                      alt: ""
                    }, null, 8, _hoisted_7)
                  ])
                ])
              ], 8, _hoisted_4),
              _createElementVNode("div", _hoisted_8, [
                isActive
                  ? (_openBlock(), _createBlock(_component_circle_popup, { key: 0 }, {
                      title: _withCtx(() => [
                        _createElementVNode("a", {
                          href: _ctx.$router.resolve({name:'excursion', params:{code: period.code}}).href,
                          class: "text-start"
                        }, [
                          _createElementVNode("h4", null, _toDisplayString(period.name), 1)
                        ], 8, _hoisted_9)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["breakpoints", "modules", "navigation"])
  ]))
}