import {apiGet} from "@/services/api";
import {History} from "./types"

export async function detail(code: string): Promise<History | undefined> {
    return await apiGet(`history/${code}`);
}

export async function index(): Promise<History[]> {
    return await apiGet("history")
}
