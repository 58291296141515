
import {defineComponent} from "vue";

export default defineComponent({
  name: "ThrottleInput",
  props: {
    delay: {
      type: Number,
      default: 1000,
    },
    modelValue: {
      type: String,
      default: ""
    }
  },
  data: ()=>({
    timeOut: 0,
    value: ""
  }),
  methods: {
    onInput(){
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(()=>{
        this.$emit("input",this.value);
        this.$emit("update:modelValue",this.value);
      },this.delay);
    }
  },
  created() {
    this.value = this.modelValue;
  }
});
