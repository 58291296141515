export type AttachmentType = "butt"

export interface Attachment {
    name: string;
    code: string;
    type: string;
    picture: string;
}

export interface WeaponResponse {
    id: number;
    title: string;
    description: string;
    icon: string;
    name: string;
    code: string;
    picture: string;
    attachments: Attachment[];
    type: string;
    file: string;
    detail_text: string;
    history: string;
}

export class Weapon {
    public name: string;
    public readonly code: string;
    public picture: string;
    public attachments: Attachment[];
    public type: string;
    public file: string;
    public detail_text: string;
    public history: string;
    public title: string;
    public description: string;

    constructor(weapon: WeaponResponse) {
        this.name = weapon.name;
        this.code = weapon.code;
        this.picture = weapon.picture;
        this.attachments = weapon.attachments;
        this.type = weapon.type;
        this.file = weapon.file;
        this.detail_text = weapon.detail_text;
        this.history = weapon.history;
        this.title = weapon.title;
        this.description = weapon.description;
    }

    getAttachments(codeType: string){
        return this.attachments.filter(attachment=>attachment.type === codeType);
    }
}
