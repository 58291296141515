
import {defineComponent} from "vue";

export default defineComponent({
  name: "ShakeHand",
  data: () => ({
    show: true,
    ending: false,
  }),
  methods: {
    endHand() {
      this.ending = true;
      setTimeout(() => {
        this.show = false;
      }, 1000)
    }
  },
  mounted() {
    document.addEventListener("touchend", this.endHand);
    document.addEventListener("mousedown", this.endHand);
  }
})
