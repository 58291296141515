
import {defineComponent} from "vue";
import {UiPopup, usePopupStore} from "@/services/popup/store";
import {Popup} from "@/services/popup/types";

export default defineComponent({
  name: "PopupView",
  data: ()=>({
    store: usePopupStore()
  }),
  computed: {
    allPopups(): UiPopup[] {
      return this.store.allPopups;
    }
  },
  mounted() {
    setInterval(()=>{
      this.store.removeOldPopups()
    },1000);
  }
});
