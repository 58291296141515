import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d16833b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row g-3" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-sm-12 col-md-4 col-lg-3",
        key: option.name
      }, [
        _withDirectives((_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["d-flex justify-content-between align-items-center", ['text-uppercase','blur-block','w-100',{'active': _ctx.optionActive(option)}]]),
          onClick: ($event: any) => (_ctx.select(option))
        }, [
          _createElementVNode("span", null, _toDisplayString(option.name), 1),
          _createElementVNode("img", {
            src: `/images/${option.value}.svg`,
            alt: option.name
          }, null, 8, _hoisted_3)
        ], 10, _hoisted_2)), [
          [_directive_ripple]
        ])
      ]))
    }), 128))
  ]))
}