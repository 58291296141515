import {reactive, ref} from "vue";

export const BackgroundMusic = reactive({
    audio: null as null | HTMLAudioElement,
    isPlaying: false,

    select(audio: HTMLAudioElement) {
        this.audio = audio;
        this.audio.loop = true;
    },
    play() {
        this.audio?.play();
        this.isPlaying = true;
        localStorage.removeItem('background-audio-paused');
    },
    pause() {
        this.audio?.pause();
        this.isPlaying = false;
        localStorage.setItem('background-audio-paused','true');
    },
    toggle() {
        if(this.isPlaying) {
            this.pause();
        }else{
            this.play();
        }
    }
})