import {PageMeta} from "@/router/meta/PageMeta";
import {useOperationsStore} from "@/services/operation/store";
import {RouteLocation} from "vue-router";

export async function getOperationMeta(route: RouteLocation): Promise<PageMeta> {
    const operation = await useOperationsStore().fetchOperation(route.params.code as string);
    if(!operation){
        throw new Error("cannot find operation " + route.params.code);
    }
    return {
        title: operation.title || operation.name,
        description: operation.description || operation.detail_text,
        picture: operation.picture,
    }
}