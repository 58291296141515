export interface WithId {
    id: number;
}

export default class Collection<TEntity> {
    private readonly entities: TEntity[];

    constructor(entities: TEntity[] = []) {
        this.entities = entities;
    }

    pad(toLength: number): Collection<TEntity> {
        if(this.entities.length <= 0){
            return new Collection(this.entities);
        }
        if (toLength <= this.entities.length) {
            return new Collection(this.entities);
        }

        const length = this.entities.length;
        const entities = this.entities.slice();
        for (let i = 0; entities.length < toLength; i++) {
            entities.push(this.entities[i % length]);
        }
        return new Collection(entities);
    }

    generateId(): Collection<TEntity & WithId> {
        const entities = this.entities.map((entity, i) => {
            return Object.assign(entity, {id: i});
        });
        return new Collection(entities);
    }

    pluck<TKey extends keyof TEntity>(key: TKey): Collection<TEntity[TKey]> {
        return new Collection(
            this.entities.map(entity => entity[key])
        );
    }

    unique(): Collection<TEntity> {
        return new Collection(Array.from(new Set(this.entities)));
    }

    values(): TEntity[] {
        return this.entities;
    }

    get(index: number) {
        return this.entities[index];
    }

    [Symbol.iterator]() {
        return this.entities[Symbol.iterator];
    }
}