
import {defineComponent, PropType} from "vue";
import {AlphaRoute} from "@/router/routes";

export default defineComponent({
  name: "FlatNav",
  props: {
    routes: {
      type: Object as PropType<AlphaRoute[]>,
      required: true,
    }
  },
  computed: {
    allRoutes(): AlphaRoute[]{
      return this.routes;
    },
    currentPath(): string {
      const paths = this.$route.path.split("/").filter(path=>path !== "");
      return `/${paths[0]}/`;
    },
    currentRouteIndex(){
      let path = this.currentPath;
      return this.allRoutes.findIndex(route=>route.url === path);
    },
    currentRoute(){
      return this.allRoutes[this.currentRouteIndex];
    },
    prevRoute(){
      if(this.currentRouteIndex > 0){
        return this.allRoutes[this.currentRouteIndex - 1];
      }
      return null;
    },
    nextRoute(){
      if(this.currentRouteIndex + 1 < this.allRoutes.length){
        return this.allRoutes[this.currentRouteIndex + 1];
      }
      return this.allRoutes[0];
    }
  }
})
