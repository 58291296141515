import {EventEmitter} from "@/modules/events/EventEmitter";

type PromiseCallback = (...args: any) => Promise<any>;
type ThrottledCallback<TAwaited> = (args: TAwaited) => void;

export default class Throttler<TTarget,
    TKey extends keyof TTarget,
    TField extends (TTarget[TKey] extends PromiseCallback ? TTarget[TKey] : PromiseCallback),
    TParams extends Parameters<TField>,
    TReturn extends ReturnType<TField>,
    TAwaited extends Awaited<TReturn>> extends EventEmitter{

    private readonly target: TTarget;
    private readonly method: TKey;
    private current_promise: Promise<TAwaited> | null;
    private next_args: TParams | null;

    constructor(target: TTarget, method: TKey) {
        super();
        this.target = target;
        this.current_promise = null;
        this.next_args = null;
        this.method = method;
    }

    doIfCan(...args: TParams) {

        if (this.current_promise) {
            this.next_args = args;
            return this;
        }
        this.emit("start");
        const method = this.method;
        if (typeof this.target[method] === "function") {
            const func = this.target[method] as TField;
            this.current_promise = func.apply(this.target, args);
            this.current_promise.then((result) => {
                this.current_promise = null;
                this.emit("success",result);

                if (this.next_args && this.next_args !== args) {
                    this.doIfCan(...this.next_args);
                }
            });
        }

        return this;
    }

    start(callback: ()=>void){
        this.on("start",callback);
        return this;
    }

    success(callback: ThrottledCallback<TAwaited>) {
        this.on("success",callback);
        return this;
    }
}