
import {defineComponent} from "vue";
import * as ContactsService from "@/services/contacts";
import {AlphaRoute, alphaRoutes} from "@/router/routes"
import NavItem from "@/components/nav/NavItem.vue";
import SkewStack from "@/components/SkewStack.vue";
import BlurBackground from "@/components/BlurBackground.vue";
import {useSiteSettingsStore} from "@/services/site-settings/store";

export default defineComponent({
  name: "SkewNav",
  components: {
    BlurBackground,
    SkewStack,
    NavItem,
  },
  data: ()=>({
    windowHeight: window.innerHeight,
    angle: window.innerWidth < 768 ? 0 : 20,
    show: false,
    contacts: ContactsService.index(),
    routes: alphaRoutes,
    settings: useSiteSettingsStore()
  }),
  methods: {
    setWindowHeight(){
      this.windowHeight = window.innerHeight;
      this.angle = window.innerWidth < 768 ? 0 : 20
    },
    close(){
      this.show = false;
    },
    open(){
      this.show = true;
    },
    isActiveRoute(route: AlphaRoute){
      return this.$route.fullPath.startsWith(route.url);
    }
  },
  computed: {
    kSin(){
      const aAngle = this.angle;
      const bAngle = 90 - aAngle;
      const aRadians = aAngle * (Math.PI / 180);
      const bRadians = bAngle * (Math.PI / 180);
      return Math.sin(aRadians) / Math.sin(bRadians);
    },
    skewX(){
      return this.windowHeight * this.kSin;
    },
    phones() {
      return this.settings.getByCode('phone')
    },
    emails() {
      return this.settings.getByCode('email')
    }
  },
  mounted() {
    window.addEventListener("resize",this.setWindowHeight);
  },
  beforeUnmount() {
    window.addEventListener("resize",this.setWindowHeight);
  }
});
