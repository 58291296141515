import {App, Directive} from "vue"

const observer = new IntersectionObserver((entries => {
    for (const entry of entries) {
        if (entry.isIntersecting) {
            entry.target.classList.add("intersection-show");
        } else {
            entry.target.classList.remove("intersection-show");
        }
    }
}));

export const useIntersectionPlugin = {

    directive: {
        mounted(el: Element) {
            observer.observe(el);
            el.classList.add("intersection");
        },
        unmounted(el) {
            observer.unobserve(el);
        }
    } as Directive,

    install(app: App<Element>) {
        app.directive("intersection", this.directive)
    }
}
