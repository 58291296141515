export class Month {
    constructor(private index: number) {
    }

    withDay(day: number): string {
        const months = [
            "Января", "Февраля", "Марта", "Апреля", "Мая", "Июня",
            "Июля", "Августа", "Сентября","Октября","Ноября", "Декабря"
        ];

        return `${day} ${months[this.index]}`;
    }
}