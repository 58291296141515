
import {defineComponent} from "vue";

export default defineComponent({
  name: "ImageBackground",
  props: {
    src: {
      type: String,
      required: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    }
  }
})
