import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fa9e664"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operation-image-item" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = { class: "thumb-image" }
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_FancyBox = _resolveComponent("FancyBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FancyBox, null, {
      default: _withCtx(() => [
        _createVNode(_component_swiper, {
          ref: "slider",
          thumbs: {swiper: _ctx.thumbsSwiper},
          modules: _ctx.modules,
          autoplay: {delay: 2000}
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, { key: image }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("a", {
                      href: `https://xn--80ajfjlb3cn7di.xn--p1ai/${image}`,
                      "data-fancybox": "gallery"
                    }, [
                      _createElementVNode("img", {
                        src: image.path || `https://xn--80ajfjlb3cn7di.xn--p1ai/${image}`,
                        alt: image.alt
                      }, null, 8, _hoisted_3)
                    ], 8, _hoisted_2),
                    _createElementVNode("h5", _hoisted_4, _toDisplayString(image.name), 1)
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["thumbs", "modules"])
      ]),
      _: 1
    }),
    _createVNode(_component_swiper, {
      "watch-slides-progress": "",
      onSwiper: _ctx.setThumbsSwiper,
      "space-between": 20,
      modules: _ctx.modules,
      breakpoints: _ctx.breakpoints
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: image }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: image.path || `https://xn--80ajfjlb3cn7di.xn--p1ai/${image}`,
                  alt: image.alt
                }, null, 8, _hoisted_6)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["onSwiper", "modules", "breakpoints"])
  ]))
}