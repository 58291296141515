import {PageMeta} from "@/router/meta/PageMeta";
import {RouteLocation} from "vue-router";
import {useWeaponsStore} from "@/services/weapon/store";

export async function getWeaponMeta(route: RouteLocation): Promise<PageMeta> {
    const weapon = await useWeaponsStore().fetchWeapon(route.params.code as string);
    if(!weapon){
        throw new Error("cannot find operation " + route.params.code);
    }
    return {
        title: weapon.title || weapon.name,
        description: weapon.description || weapon.detail_text,
        picture: weapon.picture,
    }
}