import {defineStore} from "pinia";
import {SiteSetting} from "@/services/site-settings/types";
import {index} from "@/services/site-settings/index";

export const useSiteSettingsStore = defineStore('site-settings',{
    state: ()=>({
        items: [] as SiteSetting[]
    }),
    getters: {
        settings(state) {
            return state.items
        },
        getByCode(state) {
            return (code: string) => state.items.filter(item=>item.code === code)
        }
    },
    actions: {
        async fetchAll() {
            this.items = await index()
        }
    }
})