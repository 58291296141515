

import {defineComponent} from "vue";
import SearchPopup from "@/components/SearchPopup.vue";
import WaveButton from "@/modules/audio/components/WaveButton.vue";
import {BackgroundMusic} from "@/modules/audio/BackgroundMusic";

export default defineComponent({
  name: "PageHeader",
  components: {WaveButton, SearchPopup},
  methods: {
    showAppNav(){
      (this.showNav as ()=>void)();
    }
  },
  props: {
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data: ()=>({
    audio: BackgroundMusic,
  }),
  inject: ['showNav']
});
