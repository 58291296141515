
import {defineComponent} from "vue";
import HistorySlider from "@/components/history/HistorySlider.vue";
import HistoryStaticGrid from "@/components/history/HistoryStaticGrid.vue";
import ViewSelect from "@/components/input/ViewSelect.vue";
import VideoBackground from "@/components/VideoBackground.vue";
import {useHistoryStore} from "@/services/history/store";


export default defineComponent({
  name: "HistoriesPage",
  components: {HistorySlider, VideoBackground},
  data: () => ({
    store: useHistoryStore(),
    view: "slider" as "slider" | "grid",
  }),
  computed: {
    allOperations() {
      return this.store.allOperations;
    }
  },
  mounted() {
    this.store.fetchAll();
  },
});
