
import { defineComponent } from "vue";
export default defineComponent({
    name: "ScrollTop",
    data: () => ({
        visible: false
    }),
    methods: {
        scrollTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            
        }
    },
    mounted() {
        document.addEventListener("scroll", (event) => {
            const scrolled = window.scrollY;
            const windowHeight = window.innerHeight;

            if (scrolled > windowHeight) {
                this.visible = true
            } else {
                this.visible = false
            }
        });
    }
});
