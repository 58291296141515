
import {defineComponent} from "vue";
import * as ContactsService from "@/services/contacts"
import ImageBackground from "@/components/ImageBackground.vue";

export default defineComponent({
  name: "ContactsPage",
  components: {
    ImageBackground
  },
  data: () => ({
    contacts: ContactsService.index()
  }),
})
