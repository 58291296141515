
import {defineComponent, PropType} from "vue";
import * as _ from "lodash"
import {Swiper, SwiperSlide} from "swiper/vue";
import CircularSelect from "@/components/CircularSelect.vue";
import {FreeMode, Mousewheel, Navigation} from "swiper";
import {Month} from "@/modules/helpers/Month";
import SwiperClass from "swiper/types/swiper-class";
import CirclePopup from "@/components/popup/CirclePopup.vue";
import {History} from "@/services/history/types";

export default defineComponent({
  name: "HistorySlider",
  props: {
    operations: {
      type: Object as PropType<History[]>,
      required: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    CircularSelect,
    CirclePopup,
  },
  data: () => ({
    currentOperationIndex: 0,
    isOperationSliderDragging: false,
    currentYearIndex: 0,
    modules: [FreeMode, Mousewheel, Navigation],
    navigation: {
      //Next
      nextEl: '.btn-right',//The name of the next label can be customized
      //Previous
      prevEl: '.btn-left'//The name of the previous label can be customized
    },
    swiperBreakpoints: {
      320: {
        slidesPerView: 1.5,
      },
      1000: {
        slidesPerView: 3,
      },
    }
  }),
  methods: {
    onSlideChange(swiper: SwiperClass) {
      this.currentOperationIndex = swiper.realIndex;
    },
    convertToDate(timestamp: number | string) {
      const date = new Date(timestamp);

      return new Month(date.getMonth()).withDay(date.getDate()) + ` ${date.getFullYear()}`;
    },
    onProgress(swiper: typeof Swiper) {
      swiper.updateActiveIndex();
      swiper.updateSlidesClasses();
    },
    nextSlide() {
      console.log(123)
    }
  },
  computed: {
    allOperations(): (History & { year: number })[] {
      return this.operations.map(operation => {
        return Object.assign(operation, {
          year: new Date(operation.start_date).getFullYear()
        })
      }).sort((a, b) => a.year - b.year);
    },
    currentOperation(): (History & { year: number }) {
      return this.allOperations[this.currentOperationIndex];
    },
    allYears(): number[] {
      return _.uniq(this.allOperations.map(period => period.year));
    }
  },
  watch: {
    currentYearIndex() {
      const year = this.allYears[this.currentYearIndex];
      if (this.currentOperation.year !== year) {
        this.currentOperationIndex = this.allOperations.findIndex(period => period.year === year);
      }
    },
    currentOperationIndex() {
      this.currentYearIndex = this.allYears.indexOf(this.currentOperation.year);
      if (!this.isOperationSliderDragging) {
        (this.$refs.operation_slider as typeof Swiper).$el.swiper.slideTo(this.currentOperationIndex);
      }
    }
  }
});
