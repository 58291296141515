
import {defineComponent} from "vue";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper.css"
import "swiper/css/free-mode"
import '../node_modules/video.js/dist/video-js.css'
import '@/modules/parallax/css/ripple.scss';
import SkewNav from "@/components/nav/SkewNav.vue";
import HeaderLayout from "@/layouts/HeaderLayout.vue";
import CleanLayout from "@/layouts/CleanLayout.vue";
import PopupView from "@/components/PopupView.vue";
import ScrollTop from "@/components/ScrollTop.vue";
import {useSiteSettingsStore} from "@/services/site-settings/store";

export default defineComponent({
  name: "App",
  components: {
    PopupView,
    CleanLayout,
    SkewNav,
    ScrollTop
  },
  methods: {
    showNav(){
      (this.$refs.nav as typeof SkewNav).open()
    }
  },
  computed: {
      layout(){
        return this.$route.meta.layout || HeaderLayout;
      }
  },
  provide() {
    return {
      showNav: this.showNav
    }
  },
  mounted() {
    useSiteSettingsStore().fetchAll()
  }
})
