
import {defineComponent} from "vue";
import ImageBackground from "../components/ImageBackground.vue";
import {useHumansStore} from "@/services/human/store";
import {Human} from "@/services/human/types";
import {Operation} from "@/services/operation/types";

export default defineComponent({
  name: "HumanPage",
  components: {
    ImageBackground,
  },
  data: () => ({
    store: useHumansStore()
  }),
  computed: {
    code() {
      return this.$route.params.code as string
    },
    human(): Human | undefined {
      return this.store.findByCode(this.code);
    },
    prevRoute(): Human | null {
      return this.store.getPrevByCode(this.code)
    },
    nextRoute(): Human | null {
      return this.store.getNextByCode(this.code)
    },
    statuses() {
      const statuses = {
        "dead": "Погиб",
        "commander": "Коммандир",
        "gone": "Пропал",
        "hero": "Герой"
      }

      return this.human?.status.split(',').map(status=>statuses[status])
    }
  },
  mounted() {
    // this.store.fetch(this.code);
    this.store.fetchAll();
  }
})
