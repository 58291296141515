import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operations-page-wrapper" }
const _hoisted_2 = { class: "small-container h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_history_slider = _resolveComponent("history-slider")!
  const _component_video_background = _resolveComponent("video-background")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_history_slider, { operations: _ctx.allOperations }, null, 8, ["operations"])
    ]),
    _createVNode(_component_video_background, { src: "/video/alpha.mp4" })
  ]))
}