import router from "@/router";
import {RouteRecordNormalized} from "vue-router";
import {MetaFunction} from "@/router/meta/PageMeta";
import {usePopupStore} from "@/services/popup/store";



export function PreviewAnchor(event: MouseEvent) {
    const pathname = new URL((event.target as HTMLAnchorElement).href).pathname;
    const targetPaths = pathname.split('/').filter(item => item);
    const routes = router.getRoutes();

    const route = routes.find(route => {
        const routePaths = route.path.split('/').filter(item => item);
        const sameLength = targetPaths.length === routePaths.length;

        const matchedPaths = routePaths.filter((item, index) => {
            return item.startsWith(":") || targetPaths[index] === item;
        });

        return sameLength && matchedPaths.length === routePaths.length;
    });

    if (route) {
        const resolvedRoute = router.resolve({
            name: route.name,
            params: extractRouteParams(pathname, route)
        });
        const meta = resolvedRoute.meta.getMeta as (MetaFunction | undefined);
        meta && meta(resolvedRoute).then(pageMeta => {
            const rect = (event.target as HTMLElement).getBoundingClientRect();
            rect.x += window.scrollX;
            rect.y += window.scrollY + rect.height;

            usePopupStore().addPopup({
                ...pageMeta,
                key: pathname,
                position: rect,
                href: pathname,
                sourceElement: event.target as HTMLElement
            })
        })
    }
}

function extractRouteParams(from: string, route: RouteRecordNormalized) {
    const fromPaths = from.split("/").filter(item => item);
    const routePaths = route.path.split('/').filter(item => item);
    const params: Record<string, string> = {};

    routePaths.forEach((item, index) => {
        if (item.startsWith(":")) {
            const paramName = item.replace(":", "");
            params[paramName] = fromPaths[index];
            return true;
        }
    })

    return params;
}
