
import {defineComponent} from "vue";
import * as SearchService from "@/services/search"
import Throttler from "@/services/search/throttler";
import ThrottleInput from "@/components/ThrottleInput.vue";
import {SearchGroup, SearchItem} from "@/services/search/types";
import LoadingDots from "@/components/LoadingDots.vue";

export default defineComponent({
  name: "SearchPopup",
  components: {LoadingDots, ThrottleInput},
  data: () => ({
    searchQuery: "",
    queryThrottler: new Throttler(SearchService, "detail"),
    searching: false,
    result: [] as SearchGroup[],
    filteredGroups: [] as string[],
    showPopup: false
  }),
  computed: {
    allItems(): SearchGroup[] {
      return this.result
          .filter(group => this.filteredGroups.includes(group.code));
    }
  },
  created() {
    this.queryThrottler.success((data) => {
      this.searching = false;
      this.result = data;
      this.filteredGroups = this.result.map(group => group.code);
    });
    this.queryThrottler.start(() => {
      this.searching = true;
    })
    document.addEventListener("mousedown", this.onDocumentClick);
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);
  },
  methods: {
    openSearchPage(event: KeyboardEvent) {
      window.location.href = this.$router.resolve({
        name: "search",
        query: {
          query: (event.target as HTMLInputElement).value
        }
      }).href;

    },
    toggleGroup(code: string) {
      const index = this.filteredGroups.indexOf(code);
      if (index === -1) {
        this.filteredGroups.push(code);
      } else {
        this.filteredGroups.splice(index, 1);
      }
    },
    onDocumentClick(e: MouseEvent) {
      let node = e.target as HTMLElement;
      while (node.parentElement) {
        if (node === this.$el) {
          return;
        }
        node = node.parentElement;
      }
      this.showPopup = false;
    },
    resolveRoute(group: SearchGroup, item: SearchItem) {
      try {
        return this.$router.resolve({name: group.code, params: {code: item.code}})
      } catch (e) {
        return "";
      }
    }
  },
  watch: {
    searchQuery() {
      if (this.searchQuery.length > 1) {
        this.queryThrottler.doIfCan(this.searchQuery);
      } else {
        this.result = [];
      }
    }
  }
});
