
import {defineComponent} from "vue";
import {Swiper, SwiperSlide} from "swiper/vue";
import {VideoPlayer} from '@videojs-player/vue';
import ParallaxView from "@/templates/ParallaxView.vue";
import PageHeader from "@/components/header/PageHeader.vue";
import {Autoplay, Navigation, Pagination} from "swiper";

import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import VttAudio from "@/modules/audio/components/VttArea.vue";
import PlayButton from "@/modules/audio/components/PlayButton.vue";
import WaveSurfer from "@/modules/audio/components/WaveSurfer.vue";
import {PreviewAnchor} from "@/modules/parallax/AnchorPreview";
import {useHistoryStore} from "@/services/history/store";
import {History} from "@/services/history/types";
import BigGallery from "@/components/gallery/BigGallery.vue";

export default defineComponent({
  name: "HistoryPage",
  components: {
    BigGallery,
    WaveSurfer,
    PlayButton,
    VttAudio,
    PageHeader,
    Swiper,
    SwiperSlide,
    VideoPlayer,
    ParallaxView,
  },
  data: () => ({
    audio: new Audio("/audio/audio.mp3"),
    isPlaying: false,
    audioTime: 0,
    showDetail: true,
    store: useHistoryStore(),
    modules: [Pagination, Autoplay, Navigation],
    swiperBreakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20
      },
      576: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      // when window width is >= 480px
      992: {
        slidesPerView: 7,
        spaceBetween: 30
      },
      // when window width is >= 640px
      1200: {
        slidesPerView: 8,
        spaceBetween: 50
      }
    }
  }),
  methods: {
    onVideoError() {
      if(this.operation) {
        this.operation.video = undefined
      }
    }
  },
  computed: {
    operation(): History | null {
      return this.store.getByCode(this.operationCode)
    },
    operationCode(): string {
      return this.$route.params.code as string;
    },
    prevRoute(): History | null {
      return this.store.getPrevByCode(this.operationCode)
    },
    nextRoute(): History | null {
      return this.store.getNextByCode(this.operationCode)
    }
  },
  created() {
    // this.store.fetchOperation(this.operationCode);
    this.store.fetchAll();
  },
  mounted() {
    const collection = document.querySelectorAll(".detail-text-section a");

    for (let collectionElement of collection) {
      (collectionElement as HTMLAnchorElement).addEventListener("mouseenter", PreviewAnchor);
    }
  },
  updated() {
    this.$nextTick(function(){
      const collection = document.querySelectorAll(".detail-text-section a");
      for (let collectionElement of collection) {
        (collectionElement as HTMLAnchorElement).addEventListener("mouseenter", PreviewAnchor);
      }
    })
  }
});
