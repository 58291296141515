import {defineStore} from "pinia";
import {Popup} from "@/services/popup/types";

export interface UiPopup extends Popup {
    createdAt: number;
    hovered: boolean;
}

interface PopupState {
    popups: UiPopup[]
}

const POPUP_LIFETIME_S = 1000;

export const usePopupStore = defineStore('popup', {
    state: () => ({
        popups: []
    } as PopupState),
    getters: {
        allPopups(state): UiPopup[] {
            return state.popups;
        }
    },
    actions: {
        addPopup(popup: Popup) {
            const index = this.popups.findIndex(item => item.key === popup.key);
            const newPopup = {
                ...popup,
                createdAt: +new Date(),
                hovered: false,
            };
            if (index !== -1) {
                this.popups[index] = newPopup;
            } else {
                this.popups.push(newPopup);
            }
        },
        removeOldPopups() {
            const now = +new Date();
            this.popups = this.popups.filter(popup => (popup.sourceElement && popup.sourceElement.matches(":hover")) || popup.hovered || now - popup.createdAt <= POPUP_LIFETIME_S);
        },
        clearAll() {
            this.popups = [];
        }
    }
});