import * as THREE from "three"
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

export function useCameraControls(camera: THREE.Camera, domElement: HTMLElement,size?: THREE.Vector3) {
    const controls = new OrbitControls(camera, domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.1;
    controls.minZoom = 1;
    controls.maxZoom = 1;
    controls.minDistance = 7;
    controls.maxDistance = 8;
    controls.target.set(0, 0, 0);
    controls.autoRotateSpeed = -2;
    controls.zoomSpeed = 0.75;
    controls.minPolarAngle = THREE.MathUtils.degToRad(60);
    controls.maxPolarAngle = THREE.MathUtils.degToRad(120);
    controls.target.set(0, 0, 0);

    if(size) {
        const diagonal1 = Math.sqrt(Math.pow(size.x,2) + Math.pow(size.z,2))
        const diagonal = Math.sqrt(Math.pow(diagonal1,2) + Math.pow(size.y,2))
        const radius = diagonal / 2
        controls.minDistance = radius * 1.2
        controls.maxDistance = radius * 1.5
    }

    controls.update();
}