
import {defineComponent} from "vue";
import {Swiper,SwiperSlide} from "swiper/vue";
import CircularSelect from "@/components/CircularSelect.vue";
import Collection from "@/modules/collections/Collection";
import SwiperClass from "swiper/types/swiper-class";
import {FreeMode, Mousewheel, Navigation} from "swiper";

export default defineComponent({
  name: "GroupedSlider",
  components: {
    CircularSelect,
    Swiper,
    SwiperSlide,
  },
  props: {
    entities: {
      type: Array,
      required: true,
    },
    groupKey: {
      type: String,
      required: true,
    }
  },
  data: ()=>({
    // currentEntityTitle: '',
    currentGroupIndex: 0,
    currentEntityIndex: 0,
    isEntitySliderDragging: false,
    modules: [FreeMode,Mousewheel,Navigation],
    navigation: {
      //Next
      nextEl: '.btn-right',//The name of the next label can be customized
      //Previous
      prevEl: '.btn-left'//The name of the previous label can be customized
    },
    swiperBreakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  }),
  computed: {
    allEntities(): any[] {
      return this.entities;
    },
    allGroups(): string[]{
      return new Collection(this.allEntities).pluck(this.groupKey).unique().values() as string[];
    },
    currentEntity(): any{
      return this.entities[this.currentEntityIndex];
    },
    currentTitle() : any {
      if (this.entities[this.currentEntityIndex]) {
        return (this.entities[this.currentEntityIndex] as any)[this.groupKey];
      } else {
        return ''
      }
    }
  },
  methods: {
    async onSlideChange(swiper: SwiperClass){
      this.currentEntityIndex = swiper.realIndex;
    },
  },
  watch: {
    currentGroupIndex(){
      const group = this.allGroups[this.currentGroupIndex];
      if(this.currentEntity[this.groupKey] !== group){
        this.currentEntityIndex = this.allEntities.findIndex(entity => entity[this.groupKey] === group);
      }
    },
    currentEntityIndex(){
      this.currentGroupIndex = this.allGroups.indexOf(this.currentEntity[this.groupKey]);
      if(!this.isEntitySliderDragging){
        (this.$refs.operation_slider as typeof Swiper).$el.swiper.slideTo(this.currentEntityIndex);
      }
    }
  }
});
