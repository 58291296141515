
import {defineComponent} from 'vue'
import ImageBackground from "@/components/ImageBackground.vue";

export default defineComponent({
  name: "ExcursionPage",
  components: {ImageBackground},
  mounted() {
    window.location.reload()
  }
})
