import {Weapon, WeaponResponse} from "@/services/weapon/types";
import {apiGet} from "@/services/api";

function createWeapon(weapon: WeaponResponse){
    return new Weapon(weapon);
}

export async function index(): Promise<Weapon[]>{
    return await apiGet("weapons");
}

export async function detail(code: string): Promise<Weapon>{
    const weapon: WeaponResponse | undefined = await apiGet(`weapons/${code}`);
    if(weapon){
        return createWeapon(weapon);
    }
    throw new Error("Unknown weapon id")
}
