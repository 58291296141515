import * as Three from "three"


export class Weapon3D {
    constructor(
        private model: Three.Object3D
    ) {
    }

    allButts(): Three.Object3D[] {
        const butt = this.model.children.find(children => children.name === "Empty");
        if (butt) {
            return butt.children;
        }
        return [];
    }

    isButt(object: Three.Object3D) {
        return true;
    }
}