export type EventCallback = (...args: any[]) => void;
export type EventMap = Map<string, EventCallback[]>;

export class EventEmitter {
    private readonly listeners: EventMap;

    constructor() {
        this.listeners = new Map();
    }

    // execute the callback everytime the label is trigger
    on(label: string, callback: EventCallback) {
        this.listeners.has(label) || this.listeners.set(label, []);
        this.listeners.get(label)?.push(callback);
    }

    // remove the callback for a label
    off(label: string, callback: true | EventCallback = true) {
        if (callback === true) {
            // remove listeners for all callbackfunctions
            this.listeners.delete(label);
        } else {
            // remove listeners only with match callbackfunctions
            const _off = (inListener: EventMap) => {
                const listeners = inListener.get(label);
                if (listeners) {
                    inListener.set(label, listeners.filter(value => value !== callback));
                }
            };
            _off(this.listeners);
        }
    }

    // trigger the event with the label
    protected emit(label: string, ...args: any[]) {
        let res = false;

        const _trigger = (inListener: EventMap, label: string, ...args: any[]) => {
            const listeners = inListener.get(label);
            if (listeners && listeners.length) {
                listeners.forEach((listener) => {
                    listener.apply(listener, args);
                });
                res = true;
            }
        };
        _trigger(this.listeners, label, ...args);

        return res;
    }
}