import {PageMeta} from "@/router/meta/PageMeta";
import {useOperationsStore} from "@/services/operation/store";
import {RouteLocation} from "vue-router";
import {useHistoryStore} from "@/services/history/store";

export async function getHistoryMeta(route: RouteLocation): Promise<PageMeta> {
    const history = await useHistoryStore().fetchOperation(route.params.code as string);
    if(!history){
        throw new Error("cannot find operation " + route.params.code);
    }
    return {
        title: history.title || history.name,
        description: history.description || history.detail_text,
        picture: history.picture,
    }
}