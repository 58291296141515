
import {defineComponent} from "vue";
import {alphaRoutes} from "@/router/routes";
import VideoBackground from "@/components/VideoBackground.vue";
import PageHeader from "@/components/header/PageHeader.vue";

export default defineComponent({
  name: "HomePage",
  components: {PageHeader, VideoBackground},
  data: ()=>({
    routes: alphaRoutes
  }),
});
