
import {defineComponent} from "vue";

export type ViewType = "slider" | "grid";

export default defineComponent({
  name: "ViewSelect",
  props: {
    modelValue: {
      type: String
    },
    isHuman: {
      type: Boolean,
      default: false
    },
    openFilter: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setViewType(type: ViewType){
      this.$emit("update:modelValue",type);
    }
  }
})
