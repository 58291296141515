
import {defineComponent} from "vue";

export default defineComponent({
  name: "NavItem",
  props: {
    active: {
      default: false,
      type: Boolean
    },
    title: {
      required: true,
      type: String,
    },
    image: {
      type: String,
      default: null
    },
  }
});
