import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_play_svg = _resolveComponent("play-svg")!
  const _component_pause_svg = _resolveComponent("pause-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['circle-button',{'active': _ctx.modelValue}]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['circle-item',{'active': !_ctx.modelValue}])
    }, [
      _createVNode(_component_play_svg)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['circle-item',{'active': _ctx.modelValue}])
    }, [
      _createVNode(_component_pause_svg)
    ], 2)
  ], 2))
}