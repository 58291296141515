
import { Fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    options: Object,
  },
  mounted() {
    Fancybox.bind(this.$refs.container as HTMLElement, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  updated() {
    Fancybox.unbind(this.$refs.container as HTMLElement);
    Fancybox.close();

    Fancybox.bind(this.$refs.container as HTMLElement, '[data-fancybox]', {
      ...(this.options || {}),
    });
  },
  unmounted() {
    Fancybox.destroy();
  },
});
