import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06140748"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex position-relative popup-wrapper" }
const _hoisted_2 = {
  class: "d-none d-md-block",
  style: {"position":"absolute","left":"0","top":"0","height":"100%"}
}
const _hoisted_3 = ["cx", "cy"]
const _hoisted_4 = ["x1", "y1", "x2", "y2"]
const _hoisted_5 = { class: "pt-5 info-popup" }
const _hoisted_6 = { class: "info-popup__title" }
const _hoisted_7 = {
  ref: "pointTarget",
  class: "popup-hr"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: _normalizeClass(["d-none d-md-block", {'rotate-360': _ctx.imageLoaded }]),
      src: "/images/circle.png",
      alt: "",
      ref: "circle",
      onLoad: _cache[0] || (_cache[0] = ($event: any) => {_ctx.updateLine();_ctx.imageLoaded = true;})
    }, null, 34),
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("circle", {
        cx: _ctx.circleCenter.x,
        cy: _ctx.circleCenter.y,
        r: "10",
        fill: "white"
      }, null, 8, _hoisted_3),
      _createElementVNode("line", {
        x1: _ctx.circleCenter.x,
        y1: _ctx.circleCenter.y,
        x2: _ctx.contentCenter.x,
        y2: _ctx.contentCenter.y,
        stroke: "white",
        "stroke-width": "1"
      }, null, 8, _hoisted_4)
    ])),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_7, null, 512),
      _renderSlot(_ctx.$slots, "subtitle", {}, undefined, true)
    ])
  ]))
}