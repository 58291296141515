import {defineStore} from "pinia";

export const useAppStore = defineStore('app',{
    state: ()=>({
        title: "Музей альфы"
    }),
    getters: {
        getMetaElement() {
            return (name: string) => {
                let meta = document.querySelector(`meta[name='${name}']`) as HTMLMetaElement
                if(meta) {
                    return meta
                }

                meta = document.createElement('meta')
                meta.name = name;
                document.head.append(meta)
                return meta;
            }
        }
    },
    actions: {
        setTitle(title: string) {
            document.title = title
            this.title = title
            const meta = this.getMetaElement('title')
            meta.content = title
        },
        setDescription(description: string) {
            const meta = this.getMetaElement('description')
            meta.content = description
        }
    },
})
