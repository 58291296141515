import * as THREE from "three";
import {SSAARenderPass} from "three/examples/jsm/postprocessing/SSAARenderPass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {WebGLRenderer} from "three";
import {CopyShader} from "three/examples/jsm/shaders/CopyShader";

export function createPostProcessing(renderer: WebGLRenderer, scene: THREE.Scene, camera: THREE.Camera) {
    const rtParameters = {
        minFilter: THREE.NearestFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
        stencilBuffer: false,
        type: THREE.FloatType,
        encoding: THREE.sRGBEncoding,
    };
    const size = renderer.getSize(new THREE.Vector2(0,0));
    const renderTarget = new THREE.WebGLRenderTarget(size.width, size.height, rtParameters);

    const effectComposer = new EffectComposer(renderer, renderTarget);

    const copyPass = new ShaderPass(CopyShader);
    copyPass.renderToScreen = true;

    // MSAA
    const msaaRenderPass = new SSAARenderPass(scene, camera);

    msaaRenderPass.sampleLevel = 4;
    msaaRenderPass.sampleRenderTarget = effectComposer.renderTarget2;

    effectComposer.addPass(msaaRenderPass);

    effectComposer.addPass(copyPass);
    return effectComposer;
}

export function createMaterial(material: THREE.MeshPhysicalMaterial, envMap?: THREE.Texture) {
    const newMaterial = new THREE.MeshStandardMaterial();

    newMaterial.map = material.map;

    newMaterial.roughnessMap = material.roughnessMap;
    newMaterial.metalnessMap = material.metalnessMap;

    newMaterial.normalMap = material.normalMap;

    newMaterial.normalScale = new THREE.Vector2(0.1, 0.1)

    newMaterial.precision = "highp";

    newMaterial.dithering = true;

    // if (envMap) {
    //     newMaterial.envMap = envMap;
    //     newMaterial.envMapIntensity = 1;
    // }

    return newMaterial;
}