import axios, {AxiosRequestConfig} from "axios";

export function api(){
    return axios.create({
        // baseURL: window.location.protocol + process.env.VUE_APP_API_URL,
        baseURL: process.env.VUE_APP_API_URL,
    });
}

export async function apiGet(url: string,config?: AxiosRequestConfig){
    return (await api().get(url,config)).data
}