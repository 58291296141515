import {EventEmitter} from "@/modules/events/EventEmitter";

export default class Time extends EventEmitter {
    private start: number;
    private current: number;
    private elapsed: number;
    private delta: number;
    private ticker: number;

    /**
     * Constructor
     */
    constructor() {
        super()

        this.start = Date.now()
        this.current = this.start
        this.elapsed = 0
        this.delta = 33.3
        this.ticker = 0;

        this.tick = this.tick.bind(this)
        this.tick()
    }

    /**
     * Tick
     */
    tick() {
        this.ticker = window.requestAnimationFrame(this.tick)

        const current = Date.now()

        this.delta = current - this.current
        this.elapsed = current - this.start
        this.current = current

        if (this.delta > 60) {
            this.delta = 60
        }

        this.emit('tick')
    }

    /**
     * Stop
     */
    stop() {
        window.cancelAnimationFrame(this.ticker)
    }
}
