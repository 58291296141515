import {defineStore} from "pinia";
import {Operation} from "@/services/operation/types";
import * as OperationService from "./index"

export const useOperationsStore = defineStore('operations', {
    state: () => ({
        operations: [] as Operation[]
    }),
    getters: {
        allOperations(state): Operation[] {
            return state.operations;
        },
        getByCode(state) {
            return (code: string) => state.operations.find(operation => operation.code === code) || null;
        },
        getNextByCode() {
            return (code: string) => {
                const index = this.operations.findIndex(operation => operation.code === code);
                if ((index + 1)  < this.operations.length) {
                    return this.operations[index + 1]
                } else {
                    return null;
                }
            };
        },
        getPrevByCode() {
            return (code: string) => {
                const index = this.operations.findIndex(operation => operation.code === code);
                if ((index - 1) >= 0) {
                    return this.operations[index - 1]
                } else {
                    return null;
                }
            };
        },

    },
    actions: {
        async fetchOperation(code: string) {
            const index = this.operations.findIndex(operation => operation.code === code);
            const exist = index !== -1;
            const operation = await OperationService.detail(code);

            if(!operation){
                return;
            }

            if (!exist) {
                this.operations.push(operation);
            }else {
                this.operations[index] = operation;
            }
            return operation;
        },
        async fetchAll() {
            this.operations = await OperationService.index();
        }
    }
})