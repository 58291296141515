import {defineStore} from "pinia";
import {History} from "./types"
import * as HistoryService from "./index"

export const useHistoryStore = defineStore('history', {
    state: () => ({
        histories: [] as History[]
    }),
    getters: {
        allOperations(state): History[] {
            return state.histories;
        },
        getByCode(state) {
            return (code: string) => state.histories.find(history => history.code === code) || null;
        },
        getNextByCode() {
            return (code: string) => {
                const index = this.histories.findIndex(history => history.code === code);
                if ((index + 1)  < this.histories.length) {
                    return this.histories[index + 1]
                } else {
                    return null;
                }
            };
        },
        getPrevByCode() {
            return (code: string) => {
                const index = this.histories.findIndex(history => history.code === code);

                if ((index - 1) >= 0) {
                    return this.histories[index - 1]
                } else {
                    return null;
                }
            };
        },

    },
    actions: {
        async fetchOperation(code: string) {
            const index = this.histories.findIndex(history => history.code === code);
            const exist = index !== -1;
            const operation = await HistoryService.detail(code);

            if(!operation){
                return;
            }

            if (!exist) {
                this.histories.push(operation);
            }else {
                this.histories[index] = operation;
            }
            return operation;
        },
        async fetchAll() {
            this.histories = await HistoryService.index();
        }
    }
})