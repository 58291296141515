import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-491f8546"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-wrapper grouped-slider-wrapper" }
const _hoisted_2 = { class: "grouped-slider-wrapper__title" }
const _hoisted_3 = { class: "grouped-slider-wrapper__wrap" }
const _hoisted_4 = { class: "grouped-slider-wrapper__nav" }
const _hoisted_5 = {
  class: "skew-button-left btn-left",
  ref: "btn-left"
}
const _hoisted_6 = {
  class: "skew-button-left btn-right",
  ref: "btn-right"
}
const _hoisted_7 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_circular_select = _resolveComponent("circular-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.currentTitle), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_swiper, {
          "centered-slides": true,
          breakpoints: _ctx.swiperBreakpoints,
          autoHeight: true,
          mousewheel: true,
          modules: _ctx.modules,
          initialSlide: 1,
          onSlideChange: _ctx.onSlideChange,
          onTouchStart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEntitySliderDragging = true)),
          onTouchEnd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEntitySliderDragging = false)),
          ref: "operation_slider",
          class: "w-100",
          navigation: _ctx.navigation
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createElementVNode("button", _hoisted_5, " <- ", 512)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("button", _hoisted_6, " -> ", 512)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entities, (entity, index) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
                default: _withCtx(({ isActive }) => [
                  _renderSlot(_ctx.$slots, "entity", {
                    entity: entity,
                    isActive: isActive
                  }, undefined, true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 3
        }, 8, ["breakpoints", "modules", "onSlideChange", "navigation"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_circular_select, {
        values: _ctx.allGroups,
        modelValue: _ctx.currentGroupIndex,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentGroupIndex) = $event))
      }, null, 8, ["values", "modelValue"])
    ])
  ], 64))
}