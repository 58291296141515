export class VttPeriod {
    public start_time: number;
    public end_time: number;
    public text: string;

    constructor(time: string, text: string) {
        this.text = text;
        const hms = time.split(" - ");
        this.start_time = VttPeriod.toSeconds(hms[0]);
        this.end_time = VttPeriod.toSeconds(hms[1]);
    }

    private static toSeconds(hms: string): number {
        const a = hms.split(':');
        return (+a[0]) * 60 + (+a[1]);
    }
}

export class VttFile {

    private periods: VttPeriod[] = [];

    constructor(inner: string) {
        const parts = inner.split("\r\n");
        const periods = parts.filter(part => part.startsWith("time:")).map(period => period.replace("time:", "").trim());
        const texts = parts.filter(part => part.startsWith("text:")).map(text => text.replace("text:", "").trim());
        if (periods.length !== texts.length) {
            throw new Error("")
        }
        this.periods = periods.map((period, i) => new VttPeriod(period, texts[i]));
    }

    public find(seconds: number) {
        return this.periods.find(period => period.start_time <= seconds && period.end_time >= seconds);
    }

    allPeriods() {
        return this.periods
    }
}