import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedText, (part, index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index,
        class: _normalizeClass({'vtt-mark-active': part === _ctx.currentText,'vtt-mark': 1}),
        innerHTML: part
      }, null, 10, _hoisted_1))
    }), 128))
  ]))
}